import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";

function GDPR() {
  return (
    <Wrapper>
      <Seo
        title="GDPR Privacy Policy - bugs.work"
        description="This is the GDPR Privacy Policy of bugs.work. It explains what personal data we collect, how we use it, and your rights regarding your data."
      />
      <div className="container">
        <div className="fnt-16">
          <h1 className="my-5 py-5 text-center fnt-60">GDPR Privacy Policy</h1>
          <h2 className="mb-4 fw-bold">What is the GDPR?</h2>
          <div className="mb-4">
            The GDPR, or General Data Protection Regulation, is a European privacy law that went into effect in May 2018. It regulates how personal data of individuals in the EU can be collected, used, and processed. The law impacts European companies and any business that targets European individuals or collects, uses, or processes the personal data of European individuals regardless of where the business is located. Essentially, this means the GDPR will apply to most organizations that process personal data of EU individuals—regardless of where they are established and regardless of where their processing activities take place.
          </div>
          <div className="mb-4 fw-bold">Worldwide Privacy Compliance</div>
          <div className="mb-4">
            Our customers operate in multiple jurisdictions around the world. To ensure a consistent user experience, at bugs.work we’ve adopted the GDPR principles to the entire platform and support it worldwide.
          </div>
          <div className="mb-4">
            bugs.work enables compliance with the GDPR when it processes personal data on behalf of its customers.
          </div>
          <div className="mb-4">
            Under the contractual relationship, bugs.work acts as a Processor for its customers and signs a Data Processing Agreement.
          </div>
          <div className="mb-4 fw-bold">Lawful Bases for Processing</div>
          <div className="mb-4">
            We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g., to deliver the bugs.work Services you have requested)) and “legitimate interests”.
          </div>
          <div className="mb-4">
            Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact us at <a className="accent-color" href="https://bugs.work/">bugs.work</a>.
          </div>
          <h2 className="mb-4 fw-bold">Contact</h2>
          <p className="mb-5">If you have questions or complaints regarding this Policy, please first contact us at <a className="accent-color" href="https://bugs.work/">bugs.work</a>.</p>
        </div>
      </div>
    </Wrapper>
  );
}

export default GDPR;
